import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

export default function Book() {
  return  (
      <Layout>
      <SEO 
        pagetitle="執筆書籍"
        pagedesc="株式会社わくわくスタディワールドの執筆書籍"
      />
      <div className="keni-main_wrap">
        <div className="keni-main_outer">
          <main id="main" className="keni-main">
            <div className="keni-main_inner">
              <div className="keni-section_wrap">
                <section className="keni-section">
                  <h1 className="section-title">執筆書籍</h1>
                  <div className="article-body">
                    株式会社わくわくスタディワールドでは，様々な書籍の執筆を行っております。出版された書籍には，以下のものがあります。
                  </div>
                </section>
              </div>
              <div className="keni-section_wrap">
                <section className="keni-section">
                  <h3>書籍最新版一覧</h3>
                  <div className="entry-list entry-list_style02 m0-b">
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/4eEJ0cn"><img src="https://img.ips.co.jp/ij/24/1124101040/1124101040-520x.jpg" alt="情報処理安全確保支援士教科書　教科書画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/4eEJ0cn">徹底攻略 情報処理安全確保支援士教科書 令和7年度</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/4fZ09yX"><img src="https://img.ips.co.jp/ij/24/1124101035/1124101035-520x.jpg" alt="ネットワークスペシャリスト　教科書画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/4fZ09yX">徹底攻略 ネットワークスペシャリスト教科書 令和7年度</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/3TBLZcN"><img src="https://img.ips.co.jp/ij/23/1123101063/1123101063-520x.jpg" alt="基本情報技術者　教科書画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/3TBLZcN">徹底攻略 基本情報技術者教科書 令和6年度</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/3TJICS9"><img src="https://img.ips.co.jp/ij/23/1123101134/1123101134-520x.jpg" alt="データベーススペシャリスト　教科書画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/3TJICS9">徹底攻略 データベーススペシャリスト教科書 令和6年度</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/4axx3E0"><img src="https://img.ips.co.jp/ij/23/1123101094/1123101094-520x.jpg" alt="情報セキュリティマネジメント　教科書画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/4axx3E0">徹底攻略 情報セキュリティマネジメント教科書 令和6年度</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/3s6eh5l"><img src="https://img.ips.co.jp/ij/23/1123101067/1123101067-520x.jpg" alt="応用情報技術者　教科書画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/3s6eh5l">徹底攻略　応用情報技術者教科書 令和6年度</a></p>
                        </div>
                      </div>
                    </div>
                    <div className="entry-list_item">
                      <div className="entry">
                        <figure className="entry_thumb">
                          <a href="https://amzn.to/3PoADbq"><img src="https://img.ips.co.jp/ij/22/1122101039/1122101039-520x.jpg" alt="徹底攻略 基本情報技術者の科目B実践対策 画像" width={180} height={270} /></a>
                        </figure>
                        <div className="entry_inner">
                          <p className="entry_title"><a href="https://amzn.to/3PoADbq">徹底攻略 基本情報技術者の科目B実践対策</a></p>
                        </div>
                      </div>
                    </div>
 
                  </div>
                </section>
              </div>
              <div className="keni-section_wrap">
                <section className="keni-section">
                  <h3>執筆書籍一覧</h3>
                  <p>現在までに執筆した書籍の一覧は以下のとおりです。</p>
                  <ul>
                    <li>徹底攻略　基本情報技術者教科書　インプレス　（令和6年度～）</li>
                    <li>徹底攻略　基本情報技術者の科目B実践対策（共著）　インプレス</li>
                    <li>徹底攻略　情報処理安全確保支援士教科書（共著）　インプレス（平成29年度～）</li>
                    <li>徹底攻略　情報セキュリティマネジメント教科書（共著）　インプレス（平成28年度～）</li>
                    <li>徹底攻略　データベーススペシャリスト教科書　インプレス　（平成26年度～）</li>
                    <li>徹底攻略　ネットワークスペシャリスト教科書　インプレス　（平成25年度～）
                    </li>
                    <li>徹底攻略　応用情報技術者教科書　インプレス（平成24年度～）</li>
                    <li>徹底攻略　基本情報技術者の午後対策　Python編　インプレス（初版，第2版）</li>
                    <li>基本情報技術者試験　午後問題が解ける本: ～試験のための国語と論理的思考～ ※kindle版のみ　2022.12発売終了</li>
                    <li>新　読む講義シリーズ8　システムの構成と方式　アイテック</li>
                    <li>要点整理クイックスタディ　ソフトウェア開発技術者午前問題集　翔泳社</li>
                    <li>インターネット・ネットワーク入門（共著）　アイテック</li>
                    <li>徹底解説本試験問題　応用情報技術者（共著）　アイテック（平成13年～22年）</li>
                    <li>徹底解説本試験問題　データベーススペシャリスト（共著）　アイテック（平成13年～22年）</li>
                    <li>コンピュータシステムの応用知識（共著）　アイテック</li>
                    <li>新版アルゴリズムの基礎（共著）　アイテック</li>
                    <li>システム開発の基礎　第３版（共著）　アイテック</li>
                    <li>完全分析最新過去問題集　基本情報技術者試験（共著）　エクスメディア</li>
                    <li>完全分析最新過去問題集　初級シスアド試験（共著）　エクスメディア</li>
                    <li>基本情報技術者予想問題集（共著）　アイテック</li>
                  </ul>
                  <p>など<br />
                  <font color="red">※著者名を個人名（瀬戸美月，齋藤健一，瀬戸稔代）で書いているものも含みます。</font>
                  </p>
                </section>
              </div>
              <div className="keni-section_wrap">
                <section className="keni-section">
                  <h3>サポートページ一覧</h3>
                  <p>弊社で刊行しておりますシリーズについての，最新版の書籍サポートページは以下の通りです。</p>
                  <ul>
                    <li><a href="https://github.com/wakuwakustudyworld/fe-kamokuB">徹底攻略 基本情報技術者の科目B対策 公開ソースコード</a></li>
                    <li><a href="https://github.com/mizukix/fe-python">徹底攻略 基本情報技術者の午後対策 Python編 第2版 公開ソースコード</a></li>
                    <li><a href="/books/sgtext/">徹底攻略　情報セキュリティマネジメント教科書　サポートページ</a></li>
                    <li><a href="/blog/scinfo/">徹底攻略　情報処理安全確保支援士教科書　書籍関連情報</a></li>
                    <li><a href="/blog/dbinfo/">徹底攻略　データベーススペシャリスト教科書　書籍関連情報</a></li>
                    <li><a href="/blog/nwinfo/">徹底攻略　ネットワークスペシャリスト教科書　書籍関連情報</a></li>
                  </ul>
                </section>
              </div>


            </div>
          </main>
        </div>
      </div>
      </Layout>
  )
}